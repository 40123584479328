const mock = {
    "sort":[
        {id:'1',txt:'感冒发热',href:"/druglist?cId=5363852654"},
        {id:'2',txt:'止咳化痰平喘',href:"/druglist?cId=5363871918"},
        {id:'3',txt:'活血化瘀',href:"/druglist?cId=5363876763"},
        {id:"4",txt:'降血压药',href:"/druglist?cId=1883789124"},
        {id:"5",txt:'妇科外用药',href:"/druglist?cId=5392385953"},
        {id:"6",txt:"眼科用药",href:"/druglist?cId=1883790724"}
    ],
    druglist:{
        "1":[],
        "2":[],
        "3":[]
    },
    data: {
        "1883790724":{},
        "5392385953":{},
        '1883789124':{
            tit:"降血压药",
            desc:"降压药（antihypertensive drugs） 又称抗高血压药。是一类能控制血压、用于治疗高血压的药物。降压药主要通过影响交感神经系统、肾素-血管紧张素-醛固酮系统和内皮素系统等对血压的生理调节起重要作用的系统而发挥降压效应。"
        },
        '5363852654':{
            tit:"什么是感冒",
            desc:"感冒是由多种病毒引起的上呼吸道感染，俗称“伤风”，是四季均可发生的常见急性感染性呼吸道疾病。感冒临床上又分为普通感冒与流行性感冒。 发病时多伴有以下一种或多种症状，如鼻塞、流涕、流泪、头痛、乏力、全身酸痛或咽喉疼痛，一般病程3--7天不等，如果伴发下呼吸道感染，病程可延长至10--14天。普通感冒冬春季最多见，可发生..."
        },
        "5363876763":{
            tit:"什么是活血化瘀",
            desc:"活血化瘀，用具有消散作用的、或能攻逐体内瘀血的药物治疗瘀血病证的方法。 有通畅血脉、消散瘀滞、调经止痛的作用。 适用范围很广，如瘀阻于心所致的胸闷心痛、口唇青紫。 瘀阻于肺所致的胸痛咳血、瘀阻于肝所致的胁痛痞块、瘀阻于胞宫所致的小腹疼、月经不调、痛经等；瘀阻于肢体所致的局部肿痛青紫；瘀阻于脉络所致的半身不遂等。"
        },
        "5363871918":{
            tit:"止咳化痰平喘类",
            desc:"止咳化痰平喘是以止咳化痰平喘药为主组成的方剂。咳嗽、气喘与痰涎在病机上常有密切关系，咳喘多挟痰，痰多常致咳喘。因此，临床上止咳化痰平喘药常相互配伍使用。 接止咳化痰平喘药的不同性能，分为温化寒痰、清化热痰及止咳平喘药三类"
        }
    },
    artLabelList: [
    {id:'1',txt:'儿科学',href:"/artlist?cId=3000000"},
    {id:'2',txt:'妇产科学',href:"/artlist?cId=6000000"},
    {id:'3',txt:'男科',href:"/artlist?cId=34000000"},
    {id:"4",txt:'皮肤性病科',href:"/artlist?cId=12000000"},
    {id:"5",txt:'精神心理科',href:"/artlist?cId=9000000"},
    {id:"6",txt:"生殖中心科",href:"/artlist?cId=32000000"},
    {id:"7",txt:"肿瘤科",href:"/artlist?cId=27000000"}]
}
export default mock;
