<template>
  <section>
    <div class="drug-header">
      <h2>{{title}}</h2>
    </div>
    <div class="drug-info">
      <p>{{desc}} </p>
    </div>
    <div class="drug-used js-drug-used">
      <ul class="drug-used-ul js-drug-used-ul">
        <li v-for="(item,index) in list" :key="index"
          class="drug-item"
          data-druginsurl="https://m.haodf.com/nmicroshop/addcommodity/commoditydetail?detailType=skudetail&amp;id=6371023235"
        >
         <router-link :to="item.toUrl">
            <div class="drug-row">
              <div class="drug-img-box">
                <img
                  class="drug-img"
                  :src="item.imgUrl"
                />
              </div>
              <div class="drug-des">
                <p class="drug-name">{{item.commonName}}</p>
                <p class="drug-weight ellps">10ml*12支 {{item.specification}}</p>
                <p class="drug-from ellps">{{item.factoryName}}</p>
              </div>
             <div class="drug-item-btn">查看药品详情</div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import mock from "../../mock/drug.js"
import request from "../api/index";
export default {
  props: ["cId"],
  data() {
    return {
      list: []
    };
  },
  computed:{
    title(){
      const item =  mock.data[this.cId];
      if(item){
        return mock.data[this.cId].tit
      }else{
        return ""
      }
    },
    desc(){
       const item =  mock.data[this.cId];
      if(item){
       return mock.data[this.cId].desc
      }else{
        return ""
      }
    }
  },
  mounted() {
    const success = (res) => {
      res.data.forEach(element => {
        element.toUrl = `/drugdetail?id=${element.skuId}`
      });
      this.list = res.data;
    };
    request(
      `naskpackage/ajaxdruglist?cId=${this.cId}`,
      success
    );
  }
};
</script>
<style scoped>
a:link {color: blue; text-decoration:none;} 
.drug-item {
    position: relative;
    padding: 15px 15px 55px;
    background: #fff;
    border-bottom: 5px solid #f0f0f0
}

.drug-item .drug-row {
    position: relative;
    padding-left: 70px;
    min-height: 62px
}

.drug-item .drug-row .drug-img-box {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    text-align: center;
    line-height: 60px;
    font-size: 0
}

.drug-item .drug-row .drug-img-box .drug-img {
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 58px;
    max-height: 58px
}

.drug-item .drug-row .drug-img-box .recipe-icon {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    max-width: 60px
}

.drug-item .drug-des .drug-name {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 20px;
    color: blue;
}

.drug-item .drug-des .drug-weight {
    margin-bottom: 5px;
    font-size: 15px;
    color: #323232
}

.drug-item .drug-des .drug-from {
    font-size: 15px;
    line-height: 17px;
    color: #323232
}

.drug-item .drug-item-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 110px;
    height: 30px;
    background: #46a0f0;
    border-radius: 4px;
    line-height: 30px;
    font-size: 15px;
    color: #fff;
    text-align: center
}

.drug-container .drg-list:last-child {
  padding-bottom: 0;
}
.drug-con a {
  text-decoration: none;
  color: #2670d5;
  outline: 0;
}
.drug-header {
  border-bottom: 1px solid #ccc;
  /* width: 1188px; */
  margin: 0 auto;
  padding: 40px 0 15px 12px;
  font-size: 14px;
  color: #666;
}
.drug-header span {
  color: #3f89ec;
}
.drug-header h2 {
  display: inline-block;
  font-size: 20px;
  color: #000;
  font-weight: normal;
  padding-right: 24px;
}
.drug-info {
  margin: 0 auto 20px;
  padding: 13px 12px;
}
.drug-info p {
  margin-bottom: 8px;
  font-size: 15px;
  color: #000;
  line-height: 28px;
}
.drug-info span,
.drug-info a {
  display: inline-block;
  background: #e3f1fc;
  border-radius: 6px;
  padding: 8px 10px;
  line-height: 1;
  font-size: 12px;
  color: #666;
  margin-right: 19px;
}
.drug-info a {
  color: #666;
}
.drug-info p a {
  background-color: #fff;
  font-size: 15px;
}
.drug-list {
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  margin-right: 25px;
  overflow: hidden;
  position: relative;
  height: 135px;
}
.drug-pics {
  width: 152px;
  height: 152px;
  position: absolute;
  left: 10px;
  top: 0;
  overflow: hidden;
}
.drug-pics .drug-img {
  display: table-cell;
  width: 152px;
  height: 152px;
  text-align: center;
  vertical-align: middle;
}
.drug-pics .drug-img img {
  max-width: 152px;
  max-height: 152px;
  vertical-align: middle;
}
.drug-list-con {
  margin-left: 182px;
}
.drug-list-name {
  line-height: 1;
  margin: 10px 0 20px;
}
.drug-list-name strong {
  font-size: 18px;
  color: #3f89ec;
  font-weight: normal;
  margin-right: 10px;
}
.drug-list-name span {
  font-size: 14px;
  color: #333;
}
.drug-list-info {
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.drug-tab {
  padding-top: 30px;
  border-bottom: 1px solid #ccc;
  margin: 0 25px 20px 0;
}
.drug-tab li {
  float: left;
  width: 120px;
  height: 18px;
  padding-bottom: 8px;
}
.drug-tab li a {
  display: block;
  height: 18px;
  line-height: 1;
  font-size: 18px;
  color: #000;
  width: 120px;
  text-align: center;
  background: url("//i1.hdfimg.com/ssi/pcdrugyp/prd/image/line.png") no-repeat
    right center;
}
.drug-tab li.on {
  background: url("//i1.hdfimg.com/ssi/pcdrugyp/prd/image/hover.png") no-repeat
    center bottom;
}
.drug-tab li.on a {
  color: #3f89ec;
}
.drug-tab li a:hover {
  text-decoration: none;
}
.drug-tab-con {
  margin-right: 25px;
  font-size: 14px;
  color: #000;
}
.drug-tab-con h1 {
  font-size: 23px;
  color: #333;
  font-weight: normal;
  line-height: 1;
  padding: 10px 0 30px;
  border-bottom: 1px dotted #dcdcdc;
}
.drug-con-info {
  padding: 30px 0 14px;
  border-bottom: 1px dotted #dcdcdc;
}
.drug-con-info h2 {
  font-weight: normal;
  line-height: 25px;
  font-size: 18px;
  color: #000;
  margin-bottom: 16px;
  text-indent: -10px;
}
.drug-con-info p {
  margin-bottom: 16px;
}
.dis_article {
  width: 855px;
  border: 1px solid #dcdcdc;
  margin-bottom: 12px;
}
.article_title {
  background-color: #f5f5f5;
  position: relative;
}
.article_title h2 a {
  display: block;
  width: 525px;
  padding: 15px 20px;
  font-size: 16px;
  color: #333;
  line-height: 1;
}
.article_title h2 a:hover {
  text-decoration: none;
  color: #3f89ec;
}
.article_title span {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 12px;
  color: #999;
}
.article_con {
  padding: 20px;
}
.docmsg-photo {
  float: left;
}
.docmsg-center {
  height: 58px;
  width: 58px;
  display: table-cell;
  text-align: center;
  margin-right: 15px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  vertical-align: middle;
}
.docmsg-right {
  margin-left: 78px;
}
.docmsg-right p {
  margin-bottom: 18px;
  line-height: 1;
}
.docmsg-right p a,
.docmsg-right p span {
  margin-right: 14px;
  font-size: 14px;
  color: #999;
}
.docmsg-right p a {
  color: #333;
  font-weight: 400;
}
.docmsg-right p a:hover {
  color: #3f89ec;
  font-weight: 400;
  text-decoration: none;
}
.docmsg-right p.con {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}
.docmsg-right p.con a {
  font-size: 12px;
  color: #999;
}
.docmsg-right p.con a:hover {
  color: #3f89ec;
}
.dochots {
  line-height: 1;
  text-align: right;
}
.dochots a {
  font-size: 12px;
  color: #999;
}
.doc_comment,
.doc_reader {
  padding-right: 20px;
}
.doc_reader,
.doc_comment {
  padding-right: 20px;
}
.doc_reader,
.dic_views {
  padding-left: 20px;
  background: url("//i1.hdfimg.com/ssi/pcdrugyp/prd/image/eyes.png?jkdjdk")
    no-repeat left center;
}
.doc_reader:hover,
.dic_views:hover {
  background-image: url("//i1.hdfimg.com/ssi/pcdrugyp/prd/image/eyeshover.png");
}
.doc_comment {
  padding-left: 20px;
  background: url("//i1.hdfimg.com/ssi/pcdrugyp/prd/image/comment.png")
    no-repeat left center;
}
.doc_comment:hover {
  background-image: url("//i1.hdfimg.com/ssi/pcdrugyp/prd/image/commenthover.png");
}
.dochots a:hover {
  color: #3f89ec;
  text-decoration: none;
}
.drug-relativity {
  padding: 10px 0;
}
.drug-relativity strong {
  font-weight: normal;
  font-size: 16px;
  color: #666;
  padding-right: 20px;
}
.drug-relativity span {
  font-size: 12px;
  color: #666;
  background: #e3f1fc;
  border-radius: 6px;
  padding: 6px 12px;
  margin-right: 12px;
}
.ask-list-block li {
  padding: 20px 0 20px 5px;
  border-bottom: 1px dotted #4a4a4a;
}
</style>
